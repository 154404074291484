/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(_components.p, null, "Cuanto antes borremos todos los datos que la gran G recoje sobre nosotros mejor, solo con tener una sesión de Google abierta están obteniendo datos sobre nuestras buquedas en todos sus servicios y a partir del 1 de marzo cruzarán todos estos datos consiguiendo un perfil muy completo sobre cada usuario, si te da igual tu privacidad puedes hacer caso omiso del aviso, pero si te la estimas en algo con unos pocos clics puedes evitarte muchos disgustos, ", React.createElement(_components.a, {
    href: "http://lavigilanta.info/wordpress/?p=2155",
    title: "Borrar historial de Google"
  }, "ahí va cómo hacerlo"), ".");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
